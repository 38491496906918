import styled, { css } from "styled-components";
import {
  BP,
  Colors,
  Rem,
} from "../../commons/Theme";

export const StyledCardsHive = styled.div`
  position: relative;
  min-width: 80%;
  height: ${Rem(500)};
  margin-left: ${Rem(24)};
  overflow: hidden;

  @media (${BP.ipad}) {
    min-width: calc(100% / 3.25);
  }

  &:first-child {
    @media (${BP.ipad}) {
      margin-left: 0;
    }
  }

  &:last-child {
    &:after {
      position: relative;
      display: block;
      content: '';
      left: 100%;
      width: ${Rem(24)};
      height: 1px;
      background-color: ${Colors.transparent};

      @media (${BP.ipad}) {
        display: none;
      }
    }
  }

  ${(props) =>
    props.showInfo
    && css`
      ${StyledCardsHiveInfo} {
        transform: translate(0, -100%);
      }

      ${StyledCardsHiveName} {
        color: ${Colors.yellow};
      }
    `}
`;

export const StyledCardsHiveImage = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-image: url('${(props) => props.bg}');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
`;

export const StyledCardsHiveInfo = styled.div`
  position: absolute;
  top: 100%;
  width: 100%;
  background-color: ${Colors.grayLight};
  transition: transform 0.25s ease-out 0s;
  transform: translate(0, ${Rem(-80)});
  padding: ${Rem(18)} ${Rem(20)};
`;

export const StyledCardsHiveName = styled.h2`
  font-size: ${Rem(20)};
  line-height: ${Rem(25)};
  font-weight: bold;
  padding-bottom: ${Rem(4)};
  transition: color 0.25s ease-out 0s;
`;

export const StyledCardsHiveRole = styled.h3`
  font-size: ${Rem(14)};
  line-height: ${Rem(20)};
  padding-bottom: ${Rem(32)};

  @media (${BP.ipad}) {
    font-size: ${Rem(18)};
    line-height: ${Rem(24)};
    padding-bottom: ${Rem(32)};
  }
`;

export const StyledCardsHiveDescription = styled.p`
  font-size: ${Rem(18)};
  line-height: ${Rem(24)};
`;
